<template>
  <div class="app-container">
    <el-form role="form" label-width="100px">
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('base.project.name')" label-width="60px">
            <el-select
              :style="{ width: '100%' }"
              ref="project"
              :placeholder="$t('commons.selectPlease')"
              @change="changeProject"
              clearable
              v-model="device.listQuery.projectId"
            >
              <el-option
                v-for="item in projectList"
                :label="item.projectName"
                :value="item.projectId"
                :key="item.projectId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('base.device.position')">
            <tree-select
              v-model="device.listQuery.positionId"
              :disable-branch-nodes="true"
              :multiple="false"
              :show-count="true"
              :placeholder="$t('commons.selectPlease')"
              :options="positionList"
              @select="searchCondition"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('base.category.system')">
            <tree-select
              v-model="device.listQuery.categoryId"
              :multiple="false"
              :show-count="true"
              :placeholder="$t('commons.selectPlease')"
              :options="categoryList"
              @select="searchCondition"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('base.device.name')">
            <el-input
              v-model="device.listQuery.deviceName"
              clearable
              maxlength="20"
              @keyup.enter.native="searchCondition"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('commons.status')" label-width="60px">
            <el-select
              v-model="device.listQuery.isolateStatus"
              :style="{ width: '100%' }"
              @change="searchCondition"
              clearable
            >
              <el-option
                :value="0"
                :label="$t('alarm.notIsolated')"
              ></el-option>
              <el-option :value="1" :label="$t('alarm.isolated')"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('commons.startDate')">
            <el-date-picker
              @change="pickerStart"
              v-model="device.listQuery.isolateStartTime"
              style="width: 100%"
              type="datetime"
              :placeholder="$t('commons.startTime')"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item :label="$t('commons.endDate')">
            <el-date-picker
              @change="pickerEnd"
              v-model="device.listQuery.isolateEndTime"
              style="width: 100%"
              type="datetime"
              :placeholder="$t('commons.endTime')"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button
            @click="searchCondition"
            type="primary"
            icon="el-icon-search"
            :loading="device.listLoading"
            :style="{ marginLeft: '30px' }"
            >{{ $t("commons.search") }}
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-brush"
            plain
            @click="clearForm"
            >{{ $t("commons.clear") }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="filter-container">
      <el-button
        type="warning"
        icon="el-icon-close-notification"
        size="mini"
        @click="batchIsolate"
        plain
      >
        {{ $t("alarm.batchIsolate") }}
      </el-button>
      <el-button
        type="success"
        icon="el-icon-bell"
        size="mini"
        @click="cancelBatchIsolate"
        plain
      >
        {{ $t("alarm.batchCancel") }}
      </el-button>
    </div>
    <el-table
      v-loading="device.listLoading"
      :data="device.list"
      element-loading-text="loading"
      border
      fit
      highlight-current-row
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="deviceId"
        fixed
        type="selection"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="projectName"
        :label="$t('base.project.name')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.projectName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceName"
        :label="$t('base.device.name')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.deviceName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('base.device.position')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.positionName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('base.category.system')"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.categoryName }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('commons.startDate')">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span v-if="scope.row.isolateStartTime != null">
            {{
              scope.row.isolateStartTime | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('commons.endDate')">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span v-if="scope.row.isolateEndTime != null">
            {{
              scope.row.isolateEndTime | dateFormat("YYYY-MM-DD HH:mm:ss")
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('commons.status')" width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.isolateStatus == 1">
            {{ $t("alarm.isolated") }}
          </template>
          <template v-else>
            {{ $t("alarm.notIsolated") }}
          </template>
        </template>
      </el-table-column>
      <el-table-column :label="$t('commons.actions')" align="center" width="80">
        <template slot-scope="scope">
          <template v-if="scope.row.isolateStatus === 1">
            <el-tooltip
              :content="$t('alarm.cancelIsolate')"
              placement="bottom"
              :open-delay="openDelay"
            >
              <el-button
                size="mini"
                icon="el-icon-bell"
                type="success"
                circle
                :disabled="scope.row.processing"
                @click="cancelIsolate(scope.row)"
              >
              </el-button>
            </el-tooltip>
          </template>
          <template v-else>
            <el-tooltip
              :content="$t('alarm.isolate')"
              placement="bottom"
              :open-delay="openDelay"
            >
              <el-button
                size="mini"
                icon="el-icon-close-notification"
                type="warning"
                circle
                :disabled="scope.row.processing"
                @click="enableIsolate(scope.row)"
              >
              </el-button>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="device.total > 0"
      :total="device.total"
      :page.sync="device.listQuery.current"
      :limit.sync="device.listQuery.rowCount"
      @pagination="getDeviceIsolatePage"
    />

    <!--隔离设备弹框-->
    <el-dialog
      class="app-dialog"
      :visible.sync="device.dialogVisible"
      :before-close="cancelIsolateDialog"
      width="400px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <title-icon />{{ $t("alarm.equipmentIsolation") }}
      </template>
      <el-form
        ref="isolateForm"
        :rules="isolateFormRules"
        :model="isolate.form"
        label-width="80px"
      >
        <el-row>
          <el-col :md="24">
            <el-form-item :label="$t('alarm.isolateTime')" prop="isolateTime">
              <el-input
                type="hidden"
                class="hidden"
                v-model="isolate.form.deviceId"
              ></el-input>
              <el-autocomplete
                v-model="isolate.form.isolateTime"
                :fetch-suggestions="querySearch"
                :placeholder="$t('commons.pleaseInput')"
                @select="handleSelect"
              >
                <template slot="append">{{ $t("commons.minute") }}</template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelIsolateDialog">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="handleIsolate"
          v-loading.fullscreen.lock="device.createLoading"
          >{{ $t("commons.save") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDeviceIsolatePage,
  getPositionTree,
  isolateDevice,
  batchIsolateDevice,
  cancelIsolateDevice,
  batchCancelIsolateDevice,
} from "@/api/business/base/tenant/device";
import { getCategoryList } from "@/api/business/base/tenant/category";
import { getProjectList } from "@/api/business/base/tenant/project";
import Pagination from "../../../../../components/Pagination";
import { listToTree } from "@/utils/tree";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "DeviceIsolate",
  components: {
    Pagination,
    TreeSelect,
  },
  data() {
    return {
      timeSelect: [
        { value: this.$t("commons.min30"), isolateTime: "30" },
        { value: this.$t("commons.min60"), isolateTime: "60" },
        { value: this.$t("commons.min120"), isolateTime: "120" },
        { value: this.$t("commons.min180"), isolateTime: "180" },
        { value: this.$t("commons.min360"), isolateTime: "360" },
        { value: this.$t("commons.min720"), isolateTime: "720" },
        { value: this.$t("commons.min1440"), isolateTime: "1440" },
      ],
      projectList: [],
      positionList: [],
      categoryList: [],
      openDelay: 1000,
      device: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          projectId: null,
          deviceName: null,
          positionId: null,
          categoryId: null,
          factoryCode: null,
          isolateStartTime: null,
          isolateEndTime: null,
          isolateStatus: null,
        },
        multipleSelection: [],
        dialogVisible: false,
      },
      isolate: {
        form: {
          deviceId: null,
          isolateTime: null,
        },
      },
      isolateFormRules: {
        isolateTime: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.searchCondition();
    this.getCategoryList();
    this.getProjectList();
  },
  methods: {
    pickerStart(start) {
      this.device.listQuery.isolateStartTime = start.getTime();
    },
    pickerEnd(end) {
      this.device.listQuery.isolateEndTime = end.getTime();
    },
    querySearch(queryString, cb) {
      let timeSelect = this.timeSelect;
      // let results = queryString ? timeSelect.filter(this.createFilter(queryString)) : timeSelect;
      // 调用 callback 返回建议列表的数据
      cb(timeSelect);
    },
    // createFilter(queryString) {
    //     return (timeSelect) => {
    //         return (timeSelect.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    //     };
    // },
    getProjectList(companyId) {
      getProjectList({ companyId: companyId }).then((res) => {
        this.projectList = res;
      });
    },
    changeProject() {
      if (this.device.listQuery.projectId == "") {
        this.device.listQuery.projectId = null;
        this.device.listQuery.positionId = null;
        this.positionList = [];
      } else {
        getPositionTree({ projectId: this.device.listQuery.projectId }).then(
          (response) => {
            this.device.listQuery.positionId = null;
            this.positionList = [];
            let data = listToTree(response, "positionId", "parentId");
            this.recursivePosition(data);
          }
        );
      }
    },
    //递归位置列表修改属性及数据结构
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.positionList = data;
    },
    //条件搜索
    searchCondition() {
      this.device.listQuery.current = 1;
      this.getDeviceIsolatePage();
    },
    getDeviceIsolatePage() {
      this.device.listLoading = true;
      getDeviceIsolatePage(this.device.listQuery).then((response) => {
        this.device.total = response.total;
        this.device.list = response.rows;
        this.device.listLoading = false;
      });
    },
    getCategoryList() {
      getCategoryList().then((response) => {
        let data = listToTree(response, "categoryId", "parentId");
        this.recursiveCategory(data);
      });
    },
    //递归系统列表修改属性及数据结构
    recursiveCategory(row) {
      let data = row.map((v) => {
        v.label = v.categoryName;
        v.id = v.categoryId;
        if (v.children != undefined) {
          this.recursiveCategory(v.children);
        }
        return v;
      });
      this.categoryList = data;
    },
    handleSelectionChange(data) {
      let deviceIds = [];
      data.forEach(function (obj) {
        deviceIds.push(obj.deviceId);
      });
      this.device.multipleSelection = deviceIds;
    },
    //批量隔离
    batchIsolate() {
      if (this.device.multipleSelection.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
      } else {
        this.clearIsolateForm();
        this.device.dialogVisible = true;
      }
    },
    //批量取消隔离
    cancelBatchIsolate() {
      if (this.device.multipleSelection.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("commons.multipleSelectionOne"),
        });
      } else {
        this.$confirm(
          this.$t("alarm.cancelIsolateMsg"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            //批量取消隔离
            batchCancelIsolateDevice({
              deviceIds: this.device.multipleSelection,
            }).then(() => {
              this.$message({
                type: "success",
                message: this.$t("message.operationSuccess"),
              });
              this.getDeviceIsolatePage();
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      }
    },
    //启用隔离
    enableIsolate(row) {
      this.clearIsolateForm();
      this.device.dialogVisible = true;
      this.isolate.form.deviceId = row.deviceId;
    },
    //取消隔离
    cancelIsolate(row) {
      this.$confirm(
        this.$t("alarm.cancelIsolateMsg"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          //取消隔离
          cancelIsolateDevice({ deviceId: row.deviceId }).then(() => {
            this.$message({
              type: "success",
              message: this.$t("message.operationSuccess"),
            });
            this.getDeviceIsolatePage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    clearForm() {
      this.device.listQuery.projectId = null;
      this.device.listQuery.projectId = null;
      this.device.listQuery.deviceName = null;
      this.device.listQuery.positionId = null;
      this.device.listQuery.categoryId = null;
      this.device.listQuery.factoryCode = null;
      this.device.listQuery.isolateStartTime = null;
      this.device.listQuery.isolateEndTime = null;
      this.device.listQuery.isolateStatus = null;
    },
    handleSelect(item) {
      this.isolate.form.isolateTime = item.isolateTime;
    },
    cancelIsolateDialog() {
      this.$refs.isolateForm.resetFields();
      this.device.dialogVisible = false;
    },
    handleIsolate() {
      this.$refs.isolateForm.validate((v) => {
        if (v) {
          if (this.isolate.form.deviceId == null) {
            this.$confirm(
              this.$t("alarm.isolateMsg"),
              this.$t("commons.warning"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
              }
            )
              .then(() => {
                //批量隔离
                batchIsolateDevice({
                  deviceIds: this.device.multipleSelection,
                  isolateTime: this.isolate.form.isolateTime,
                }).then(() => {
                  this.device.dialogVisible = false;
                  this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                  });
                  this.getDeviceIsolatePage();
                });
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
          } else {
            //设备隔离
            isolateDevice({
              deviceId: this.isolate.form.deviceId,
              isolateTime: this.isolate.form.isolateTime,
            }).then(() => {
              this.device.dialogVisible = false;
              this.$message({
                type: "success",
                message: this.$t("message.operationSuccess"),
              });
              this.getDeviceIsolatePage();
            });
          }
        }
      });
    },
    clearIsolateForm() {
      this.isolate.form.deviceId = null;
      this.isolate.form.isolateTime = null;
    },
  },
};
</script>

<style scoped>
.app-dialog >>> .el-form-item {
  margin-bottom: 0px;
}
</style>
